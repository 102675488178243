import { render, staticRenderFns } from "./CommonTestimonialsBlock.vue?vue&type=template&id=86b04786&scoped=true"
import script from "./CommonTestimonialsBlock.vue?vue&type=script&lang=ts"
export * from "./CommonTestimonialsBlock.vue?vue&type=script&lang=ts"
import style0 from "./CommonTestimonialsBlock.vue?vue&type=style&index=0&id=86b04786&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "86b04786",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CommonVideoPlayerDialog: require('/Users/teodor/Documents/Workspace/website/wp-nuxt/components/common/CommonVideoPlayerDialog.vue').default})
